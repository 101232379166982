.hero-slider-container{
    position: relative;
    z-index: 1;
}

.hero-slider-container .slick-slide{
   
}

.hero-slider__img-wrapper{
    display: block;
    position: absolute;
    z-index: -1;
    height: 100%;
    width: 100%;
    left: 0;
    top: 0;
}
.hero-slider__img{
    width: 100%;
    height: 100%;
    object-fit: cover;
}

.hero-slider__img-overlay{
    display: flex;
    justify-content: flex-end;

    width: 100%;
    min-height: 384px;
}
.hero-slider__img-overlay-int{
    display:flex;
    align-items: center;
    justify-content: center;
    padding: 40px;

    width: 100%;
    background: rgba(0,85,165,.8);
    color: white;
}

.hero-slider__subheading,
.hero-slider__heading{
    color: inherit !important;
}

.hero-slider__subheading{
    margin-left: auto;
    margin-right: auto;
    margin-bottom: 5px;

    font-style: italic;
    font-family: 'PT Serif'!important;
    line-height: 1.25;
}
.hero-slider__heading{
    font-family: 'Open Sans'!important;
    font-weight: 700;
    font-size: 1.75rem;
}


.hero-slider__qr-container{
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    margin-bottom: 20px;
}

.hero-slider__link-wrapper{
    display: flex;
    justify-content: center;

    margin-top: 30px;
}
body .default-button.hero-slider__link{
    margin: 0 !important;
}

@media ( min-width: 768px ) {
    .hero-slider__img-overlay{
        padding-right: 8%;
    }
    .hero-slider__img-overlay-int{
        max-width: 375px;
    }
}

@media ( min-width: 1024px ) {
    .hero-slider__img-overlay{
        padding-right: 15%;
    }
    .hero-slider__subheading{
        font-size: 22px;
        max-width: 225px;
    }
    .hero-slider__heading{
        font-size: 2rem;
    }
}